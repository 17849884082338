.text-small {
  font-size: 75%;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.left {
  float: left;
}

.right {
  float: right;
}

.display__inline {
  display: inline;
}

/* Padding Helpers */
.padding-tb {
  padding-top: 3%;
  padding-bottom: 3%;
}

/* Basic Demo Styles */
.site-container {
  /* max-width: 320px; */
  margin: 10px auto;
  padding-left: 0.6em;
  padding-right: 0.6em;
}
@media screen and (min-width: 60.063em) {
  /* .site-container {
    max-width: 400px;
  } */
}

.footer__content {
  margin-top: 1.6rem;
}

/* Card */
.card-form {
  position: relative;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.24), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  padding: 1em;
}
.card h3 {
  font-weight: bold;
}

/* Material Design Form Inputs */
.form__wrapper {
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-bottom: 1.6rem;
  width: 100%;
  vertical-align: top;
}

.form__wrapper--submit {
  padding: 1.6rem 0;
}

.form__input {
  display: block;
  position: relative;
  margin-top: 1em;
  padding: 0.84em 0;
  width: 100%;
  box-sizing: border-box;
  color: #444;
  font-size: 1.6rem;
  outline: 0;
  border: none;
  border-bottom: solid 1px #ddd;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -moz-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -o-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 96%,
    #2196f3 4%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 96%,
    #2196f3 4%
  );
  background-position: -400px 0;
  background-size: 400px 100%;
  background-repeat: no-repeat;
  /**
   * Remove default box-shadow for required pseudo classes 
   */
  /**
   *  When input contain a class of .error 
   */
}
.form__input:required {
  box-shadow: none;
}
.form__input.error {
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -moz-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -o-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 96%,
    #f44336 4%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 96%,
    #f44336 4%
  );
  background-position: 0 0;
  background-size: 400px 100%;
  background-repeat: no-repeat;
}
.form__input:focus {
  background-position: 0 0;
  color: #444;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -moz-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -o-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
}

/* Form Label */
.form__label {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0;
  width: 100%;
  height: calc(100% - 1em);
  text-align: left;
  pointer-events: none;
  color: #999;
}

/* Form Label Content */
.form__label-content {
  position: absolute;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -moz-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -o-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
}

/**
 * 1. When input is focused move the label to top
 * 2. when the input field has a value move the label to top
 */
.form__input:focus ~ .form__label .form__label-content,
.form--filled .form__label-content {
  font-size: 1.4rem;
  -webkit-transform: translate3d(0, -65%, 0);
  -moz-transform: translate3d(0, -65%, 0);
  transform: translate3d(0, -65%, 0);
}

/**
 * Error Styles
 * .error class generated by jquery.validate.min.js plugin
 */
.error {
  font-size: 1.4rem;
  color: #f44336;
  display: block;
}

.proceed {
  background-color: #2196f3;
}

.btn.btn-block {
  width: 100%;
  display: block;
}

/* Modal Styles */
/* .modal {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  padding: 1em;
  z-index: 999;
  border-radius: 2px;
  -webkit-transform: translate3d(0, 100%, 0);
  -moz-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: transform 0.3s cubic-bezier(0, 0.795, 0, 1);
  -moz-transition: transform 0.3s cubic-bezier(0, 0.795, 0, 1);
  -ms-transition: transform 0.3s cubic-bezier(0, 0.795, 0, 1);
  -o-transition: transform 0.3s cubic-bezier(0, 0.795, 0, 1);
  transition: transform 0.3s cubic-bezier(0, 0.795, 0, 1);
} */

/*Bootstrap Calendar*/
.datepicker {
  border-radius: 0;
  padding: 0;
}
.datepicker-days table thead,
.datepicker-days table tbody,
.datepicker-days table tfoot {
  padding: 10px;
  display: list-item;
}
.datepicker-days table thead,
.datepicker-months table thead,
.datepicker-years table thead,
.datepicker-decades table thead,
.datepicker-centuries table thead {
  background: #3546b3;
  color: #ffffff;
  border-radius: 0;
}
.datepicker-days table thead tr:nth-child(2n + 0) td,
.datepicker-days table thead tr:nth-child(2n + 0) th {
  border-radius: 3px;
}
.datepicker-days table thead tr:nth-child(3n + 0) {
  text-transform: uppercase;
  font-weight: 300 !important;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}
.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
  padding: 11px 13px;
}
.datepicker-months table thead td,
.datepicker-months table thead th,
.datepicker-years table thead td,
.datepicker-years table thead th,
.datepicker-decades table thead td,
.datepicker-decades table thead th,
.datepicker-centuries table thead td,
.datepicker-centuries table thead th {
  border-radius: 0;
}
.datepicker td,
.datepicker th {
  border-radius: 50%;
  padding: 0 12px;
}
.datepicker-days table thead,
.datepicker-months table thead,
.datepicker-years table thead,
.datepicker-decades table thead,
.datepicker-centuries table thead {
  background: #3546b3;
  color: #ffffff;
  border-radius: 0;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-image: none;
}
.datepicker .prev,
.datepicker .next {
  color: rgba(255, 255, 255, 0.5);
  transition: 0.3s;
  width: 37px;
  height: 37px;
}
.datepicker .prev:hover,
.datepicker .next:hover {
  background: transparent;
  color: rgba(255, 255, 255, 0.99);
  font-size: 21px;
}
.datepicker .datepicker-switch {
  font-size: 24px;
  font-weight: 400;
  transition: 0.3s;
}
.datepicker .datepicker-switch:hover {
  color: rgba(255, 255, 255, 0.7);
  background: transparent;
}
.datepicker table tr td span {
  border-radius: 2px;
  margin: 3%;
  width: 27%;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-color: #3546b3;
  background-image: none;
}
.dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.datepicker-dropdown.datepicker-orient-top:before {
  border-top: 7px solid rgba(0, 0, 0, 0.1);
}
