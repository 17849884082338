.welcomeform h3 {
  margin-bottom: 1.2rem;
  line-height: 1.5;
  font-size: 1.8rem;
  /* text-align: center; */
}
.text-small {
  font-size: 75%;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.left {
  float: left;
}

.right {
  float: right;
}

.display__inline {
  display: inline;
}

/* Padding Helpers */
.padding-tb {
  padding-top: 3%;
  padding-bottom: 3%;
}

/* Basic Demo Styles */
.site-container {
  margin: 100px auto;
  padding-left: 0.6em;
  padding-right: 0.6em;
}
@media screen and (min-width: 60.063em) {
}

.footer__content {
  margin-top: 1.6rem;
}

/* Card */
.welcomeform .card {
  position: relative;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.24), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  padding: 1em;
}
.welcomeform .card h3 {
  font-weight: bold;
}

/* Material Design Form Inputs */
.form__wrapper {
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-bottom: 1.6rem;
  width: 100%;
  vertical-align: top;
}

.form__wrapper--submit {
  padding: 1.6rem 0;
}

.form__input {
  display: block;
  position: relative;
  margin-top: 1em;
  padding: 0.84em 0;
  width: 100%;
  box-sizing: border-box;
  color: #444;
  font-size: 1.6rem;
  outline: 0;
  border: none;
  border-bottom: solid 1px #ddd;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -moz-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -o-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 96%,
    #2196f3 4%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 96%,
    #2196f3 4%
  );
  background-position: -400px 0;
  background-size: 400px 100%;
  background-repeat: no-repeat;
  /**
   * Remove default box-shadow for required pseudo classes 
   */
  /**
   *  When input contain a class of .error 
   */
}
.form__input:required {
  box-shadow: none;
}
.form__input.error {
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -moz-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -o-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 96%,
    #f44336 4%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 96%,
    #f44336 4%
  );
  background-position: 0 0;
  background-size: 400px 100%;
  background-repeat: no-repeat;
}
.form__input:focus {
  background-position: 0 0;
  color: #444;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -moz-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -o-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
}
span.has-error {
  color: #f44336;
  font-weight: 600;
}
/* Form Label */
.form__label {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0;
  width: 100%;
  height: calc(100% - 1em);
  text-align: left;
  pointer-events: none;
  color: #999;
}

/* Form Label Content */
.form__label-content {
  position: absolute;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -moz-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -o-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
}

/**
 * 1. When input is focused move the label to top
 * 2. when the input field has a value move the label to top
 */
.form__input:focus ~ .form__label .form__label-content,
.form--filled .form__label-content {
  font-size: 1.4rem;
  -webkit-transform: translate3d(0, -65%, 0);
  -moz-transform: translate3d(0, -65%, 0);
  transform: translate3d(0, -65%, 0);
}

/**
 * Error Styles
 * .error class generated by jquery.validate.min.js plugin
 */
.error {
  font-size: 1.4rem;
  color: #f44336;
  display: block;
}

/* Basic Buttons */
.welcomeform .btn {
  display: inline-block;
  padding: 0.6em 1.5em;
  border-radius: 2px;
  /* background-color: #2196f3; */
  color: #fff;
  border: 0;
  outline: none;
  cursor: pointer;
  font-family: inherit;
  font-weight: 400;
  font-size: 1.6rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.24), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.2s ease;
}
/* .btn:focus, .btn:hover { */
/* background-color: #0d8aee; */
/* } */
.welcomeform .btn.btn-block {
  width: 85%;
  display: block;
}

.welcomediv {
  /* background-image: url("/img/gray-floral.png"); */
  /* background-size: cover; */
  /* opacity: 1; */
  /* background-color: #e29f9f */
  /*  */
  /* background-image: -webkit-gradient(linear, left bottom, left top, from(#a3bded), to(#6991c7));  */
  /* background-image: linear-gradient(to top, #a3bded 0%, #6991c7 100%);  */

  /* background-color: #dd3e3e; */
  /* background-image: linear-gradient(147deg, #ff833b 0%, #fd3838 74%); */
}

.home_church {
  font-size: 1rem;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

optgroup {
  font-size: 1.5rem;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}
