.cont {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.services-section {
  background: #f6f8f9;
}

.spad {
  padding: 100px 0;
}

.mb-5 {
  margin-bottom: 10%;
}

.blog-section {
  background: #f7f8f8;
}