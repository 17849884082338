/* .icon-slide {
  color: #fff;
  font-size: 3rem;
  padding: 2px;
  font-weight: bold;
} */
.quoted span {
  color: #212121 !important;
  font-weight: bold;
  font-size: 5vw;
}

.icon-slide {
  position: absolute;
  /* top: 50%; */
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 auto !important;
  z-index: 9;
  /* border: none; */
  color: #fff;

  /* background-color: #ffd13b; */
  /* background-image: linear-gradient(147deg, #ffe53b 0%, #fd3838 74%); */
  font-size: 3rem;
  border-radius: 50%;
  text-align: center;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  text-align: center !important;
}

.event-slide-img {
  height: 70%;
  width: 100% !important;
  padding: 0;
  /* border: 2px solid gold; */
}

.event-slide-img img {
  height: 100%;
  width: 100%;
  /* background-size: cover; */
  /* border: 2px solid green; */
  object-fit: cover;
}

.content-details h3 {
  /* font-size: .8vw !important;
  font-family: "Lora", serif;
  font-style: italic; */
  filter: brightness(100%);
  font-size: 1.2vw !important;
  font-weight: 400;
}

.content-details p {
  font-size: 1.2vw !important;
  font-weight: 400;
  filter: brightness(100%);
  /* font-family: "Lora", serif; */
  /* font-style: italic; */
}

.content a {
  text-decoration: none;
  color: #fff;
}

.event-list-section .fa-spinner {
  color: #dd3e3e;
  /* background-color: #dd3e3e; */
  /* background-image: linear-gradient(147deg, #853405 0%, #fd3838 74%); */
  font-size: 2.5em;
  text-align: center;
}

.shadow-quote {
  -webkit-box-shadow: 10px 10px 5px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px -9px rgba(0, 0, 0, 0.75);
}

.img-scale {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  /* transition: all .2s ease-out !important; */
}

.img-scale:hover {
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
  transform: translate(0px, -25px);
  transform: scale(1.2);
  z-index: 99999;
}

/* ----- DIALOG/MODAL ----- */
/* FADE/SCALE EFFECT */
.modal.fade .modal-dialog {
  opacity: 0;
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  -o-transform: scale(0.1);
  transform: scale(0.1);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.modal.fade.in .modal-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/* DIALOG CONTENT */
.modal-content {
  border: none;
  border-radius: 2px;
  -webkit-box-shadow: 0 40px 77px rgba(0, 0, 0, 0.22),
    0 27px 24px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 40px 77px rgba(0, 0, 0, 0.22),
    0 27px 24px rgba(0, 0, 0, 0.2);
  box-shadow: 0 40px 77px rgba(0, 0, 0, 0.22), 0 27px 24px rgba(0, 0, 0, 0.2);
}

/* DIALOG BODY */
.modal-body {
  padding: 0 24px;
}

.modal-body p {
  font-weight: 400;
  font-size: 14px;
  color: #212121;
}

.modal-body .lead {
  font-weight: 300;
  font-size: 16px;
  color: #757575;
}

.modal-body p:last-child,
.modal-body .lead:last-child {
  margin-bottom: 0;
}

/* DIALOG FOOTER */
.modal-footer {
  margin-top: 24px;
  padding: 8px 0;
  /* border-top: none; */
}

.modal-footer .btn {
  height: 36px;
  margin-right: 8px;
  padding: 8px 10px;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 500;
  color: #212121;
  background-color: #fff;
}

.modal-footer .btn:focus {
  outline: none;
  box-shadow: none;
}

.modal-footer .btn:focus,
.modal-footer .btn:hover {
  color: #00796b;
}

.modal-footer .btn + .btn {
  margin-left: 0;
}

.modal-footer .btn + .btn:last-child {
  margin-left: -4px;
}
