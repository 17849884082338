/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.copyright {
  color: #ffffff !important;
}

.site-logo {
  width: auto;
  height: 12vh;
  margin-top: -30px;
  margin-bottom: -20px;
}
.login-div {
  margin: 40px 0px 50px 0px;
}

/* play button */
/* .video-thumbnail {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 30px;
} */
.video-thumbnail:before {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "\f01d";
  font-family: FontAwesome;
  font-size: 100px;
  color: #fff;
  opacity: 0.8;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
}
.video-thumbnail:hover:before {
  color: #eee;
}

/* play button */
.player .vp-text-alert-wrapper {
  background-image: linear-gradient(to bottom, rgba(20, 21, 22, 0.3), #141516);
  cursor: default;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-size: 1.6em;
  font-weight: 700;
}

.player .vp-text-alert-wrapper .vp-alert-text,
.player .vp-text-alert-wrapper .vp-alert-time {
  top: 50%;
  transform: translateY(-50%);
  position: relative;
  width: 100%;
}

.player .hidden,
.player .only-in-fullscreen {
  display: none !important;
}

.player .vp-text-alert-wrapper .vp-alert-time .vp-live-start-time-title {
  margin-bottom: 9px;
}

.player .vp-text-alert-wrapper .vp-alert-time .vp-live-start-time-body {
  font-weight: 400;
  font-size: 3.2em;
}
