.cell-header-text {
  /* border: 2px solid red; */
  margin-bottom: 2px;
}

.tabs input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.tabs .row {
  display: flex;
  /* border: 2px solid red; */
}

.tabs .row .col {
  flex: 1;
}

.tabs .row .col:last-child {
  margin-left: 1em;
}

/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
  /* border: 2px solid red; */
  margin-bottom: 8px;
}

.tab {
  width: 100%;
  overflow: hidden;
}

.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  font-weight: bold;
  cursor: pointer;
  /* Icon */
}

.tab-label:hover {
  background: #b42b2b;
  color: #fff;
}

.tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}

.tab-content {
  max-height: 0;
  padding: 0 1em;
  /* color: #2c3e50; */
  /* background: white; */
  transition: all 0.35s;
}

.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  /* background: #2c3e50; */
  cursor: pointer;
}

input:checked + .tab-label {
  background: #b42b2b;
  color: #fff;
}

input:checked + .tab-label::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}

/*TABLE WRAP  */

/*TABLE WRAP  */
