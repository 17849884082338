.login-container {
  margin-top: 5%;
  margin-bottom: 5%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.login-form-1 {
  padding: 5%;
  /* box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19); */
  /* background-image: url("/img/glory.png");
  background-repeat: no-repeat;
  background-position: center; */
}

.login-form-1 h3 {
  text-align: center;
  color: #333;
}

.login-form-2 {
  padding: 5%;
  /* background: rgb(221, 62, 62);
  background: linear-gradient(
    60deg,
    rgba(221, 62, 62, 1) 42%,
    rgba(217, 131, 36, 1) 83%
  ); */
  background: rgb(221, 62, 62);
  background: linear-gradient(
    60deg,
    rgba(221, 62, 62, 1) 19%,
    rgba(221, 62, 62, 1) 54%,
    rgba(217, 131, 36, 1) 88%
  );
  /* box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19); */
}

.login-form-2 h3 {
  text-align: center;
  color: #fff;
}

.login-container form {
  padding: 10%;
}

.btnSubmit {
  width: 50%;
  border-radius: 1rem;
  padding: 1.5%;
  border: none;
  cursor: pointer;
}

.login-form-1 .btnSubmit {
  font-weight: 600;
  color: #fff;
  background-color: #dd3e3e;
}

.login-form-2 .btnSubmit {
  font-weight: 600;
  color: #dd3e3e;
  background-color: #fff;
}

.login-form-2 .ForgetPwd {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

.login-form-1 .ForgetPwd {
  color: #dd3e3e;
  font-weight: 600;
  text-decoration: none;
  font-size: 17px;
}

/* CUSTOM STYLES */
.img-logo {
  width: 170px;
  height: 170px;
  margin: auto;
  border-radius: 50%;
  border: 2px solid #e94057;
  /* background-color: #e6243d; */
  /* background: rgb(221, 62, 62);
  background: linear-gradient(
    60deg,
    rgba(221, 62, 62, 1) 19%,
    rgba(221, 62, 62, 1) 54%,
    rgba(217, 131, 36, 1) 88%
  ); */
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.img-logo img {
  width: 50%;
  /* border-radius: 50%; */
  margin: 24px auto;
  position: inherit;
}

/* .img-logo img {
  width: 100%;
  height: auto;
} */
.login-form-1 h4 {
  font-weight: bold;
  font-size: 1.7rem;
  font-family: "Lora", serif;
  font-style: italic;
  /* font-size: 15px; */
  /* color: #dd3e3e; */
  line-height: inherit;
  /* border: 2px solid red; */
  margin-top: 5px;
  /* margin-top: 35px; */

  /* min-width: 76vh; */
}

.rem {
  color: #fff;
}

.checkbox {
  padding-left: 20px;
}

.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
  color: #fff;
}

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555;
}

.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
  opacity: 0;
  z-index: 1;
}

.checkbox input[type="checkbox"]:focus + label::before,
.checkbox input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
  font-family: "FontAwesome";
  content: "\f00c";
}

.checkbox input[type="checkbox"]:disabled + label,
.checkbox input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="radio"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

/* CUSTOM STYLES */
